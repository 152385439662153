<template>
  <v-radio-group
    v-model="localValue"
    :class="['input']"
    dense
    row
    :filled="input.filled"
    :disabled="disabled"
  >
    <v-radio
      v-for="(option, index) in input.options"
      color="primary"
      :key="index"
      :label="option.display"
      :value="option.value"
    />
  </v-radio-group>
</template>

<script>
export default {
  name: "CheckBox",

  props: {
    input: {
      type: Object,
      required: false,
    },
    disabled: Boolean,
  },

  data: () => ({}),

  computed: {
    localValue: {
      get() {
        return this.input.value
      },
      set(newValue) {
        this.$emit("update:value", newValue)
      },
    },
  },

  methods: {},
}
</script>

<style lang="scss" scoped>
.input {
  margin-bottom: -30px;
}
</style>
